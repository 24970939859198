import React  from "react"
import Layout from "../components/layout"
import Row from "../components/layout/row"

const Holders = props => {
  return (
    <Layout>
      <Row margin="2rem auto">
              <h1>Ошибка 404</h1>
              <p>Такой страницы не существует, попробуйте снова.</p>
      </Row>
    </Layout>
  )
}



export default Holders
